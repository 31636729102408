body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.slick-slider .slick-list {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}

.my-slider {
  z-index: 200;
}
.single-slider {
  padding: 20px;
}

@keyframes snowFlakesAnima {
  0% {
    transform: translate(-10px, -100px);
  }

  25% {
    transform: translate(10px, 100px);
  }
  50% {
    transform: translate(0px, 00px);
  }
  75% {
    transform: translate(10px, 100px);
  }
  100% {
    transform: translate(-10px, -100px);
  }
}

.snowflakes-home-hero {
  animation: snowFlakesAnima 20s ease-in-out infinite;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(./assets/font/Nunito-Light.ttf);
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./assets/font/Nunito-Regular.ttf);
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(./assets/font/Nunito-Medium.ttf);
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(./assets/font/Nunito-SemiBold.ttf);
}
@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/font/Nunito-Bold.ttf);
}
@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(./assets/font/Nunito-ExtraBold.ttf);
}
