.privacy-policy {

}
.privacy-policy  .privacy-policy-heading {
    font-size: 1rem;
    /* line-height: 1.375; */
    font-family: Nunito;
    /* text-align: center; */
    color: #5380EA;
    font-weight: 600;
    width: 200px;
    margin: 0;
}
.line-through {
    margin: 5px 0;
    width: 70px;
}

.privacy-policy .privacy-policy-list {
    width: 200px;
    list-style: square;
    cursor: pointer;
}

.privacy-policy .privacy-policy-list li:hover {
    text-decoration: underline;

}

.terms-and-conditions-links{
    text-decoration: none;
    color: white;
}