.santa-form-heading {
    color: #5380EA;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19);
    font-family: Nunito;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.santa-form-description {
    color: #03062E;
    text-align: justify;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}